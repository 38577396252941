import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import logo from '../images/AccountCenterLogo.svg';
import { LocalStorage } from '../services/LocalStorage.service';
import { DataContext } from '../services/DataProvider.service';

export const ResponsiveAppBar = ({ portal, slug }) => {
    const account = portal.accountDetails;
    const portalAccount = portal.portalUser[0];
    const dataProvider = useContext(DataContext);
    const auth = dataProvider?.auth.getAuth;
    const [company] = useState('Synchrony');
    const navigate = useNavigate();

    const logOut = () => {
        LocalStorage.clear();
        dataProvider?.setAuth({
            getAuth: false
        });
        navigate(`/${slug}`);
    };

    return (
        <>
            <AppBar position="static" sx={{
                '&.MuiAppBar-root': {
                    backgroundColor: '#131421'
                }
            }}>
                <Container maxWidth="auto" sx={{
                    '&.MuiContainer-root': {
                        backgroundColor: '#131421',
                        boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.15)',
                    }
                }}>
                    <Toolbar disableGutters sx={{
                        '&.MuiToolbar-root': {
                            backgroundColor: '#131421',
                        }
                    }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href={`/${slug}`}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={logo} alt='logo' width={240} />
                        </Typography>
                        {!isMobile && <p className='welcome-msg-app-bar'>{`Welcome, ${portalAccount?.firstName}!`}</p>}
                        <div className='app-bar-btns'>
                            <div className='app-bar-btn-synchrony'>
                                <Select
                                    label={account.companyName}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#26292E',
                                        width: 170,
                                        height: 50,
                                        border: "1px solid transparent",
                                        color: "#fff",
                                        "& .MuiSvgIcon-root": {
                                            color: "white",
                                        }
                                    }}
                                    value={company}
                                >
                                    <MenuItem value={"Synchrony"}>{account?.companyName}</MenuItem >
                                    <MenuItem onClick={logOut} value={"Logout"}><span className='logout-icon'><ExitToAppOutlinedIcon /></span>Log out</MenuItem >
                                </Select>
                            </div>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};
export default ResponsiveAppBar;
