import { LocalStorage } from "../services/LocalStorage.service";
const isLoggedIn = () => {
  const user = LocalStorage.get("user");
  const token = LocalStorage.get("token");
  if(user && token){
    return true
  } else {
    return false
  }
};

export { isLoggedIn };