import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordStrengthBar from 'react-password-strength-bar';
import {
    getAuth,
    verifyPasswordResetCode,
    confirmPasswordReset,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import AddPlus from '../images/AddPlus.svg';
import { textFieldSx, circularProgress } from '../constants';
import { resendEmailIfPossible, removeOobCode } from '../services/Api.service';
import urlHelper from '../helpers/urlHelper';


export const ResetUserPassword = ({reason}) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordIsStrong, setPasswordIsStrong] = useState(false);

    const resetPassword = async () => {
        if (password && confirmPassword) {
            if (password === confirmPassword) {
                if (!passwordIsStrong) {
                    toast.warn('Your password is not strong enough! The password must be at least six characters and contain at least one letter, one number and a symbol!', { theme: 'dark' });
                    return;
                }
                setLoading(true);
                const url = window.location.search;
                const { oobCode, purpose, gotolocation } = urlHelper.extractParams(url, ['oobCode', 'purpose', 'gotolocation']);
                const slug = urlHelper.extractPathName(gotolocation);

                const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
                const auth = await getAuth(app);
                try {
                    const email = await verifyPasswordResetCode(auth, oobCode)
                    if (email) {
                        try {
                            await confirmPasswordReset(auth, oobCode, password);
                            //Redirect to portal page.
                            const url = window.location.search
                            let params = new URLSearchParams(url);
                            const gotolocation = params.getAll('gotolocation')[0];

                            removeOobCode({slug, oobCode});
                            // Check if we have gotolocation url param and replace location
                            if (gotolocation) {
                                window.location.replace(gotolocation);
                            }
                        } catch (err) {
                            console.log(err, err.code, 'all errors')
                        }
                    } else {
                        toast.error('Error: We were unable to identify your email. Please contact the SOC team.', { theme: 'dark' });
                    }
                } catch (err) {
                    // TODO: Inegrate sentry
                    toast.error('Error: Invalid or expired action code.', { theme: 'dark' });
            
                    setLoading(false);
                    await resendEmailIfPossible({
                        oobCode,
                        purpose,
                        slug
                    });
                }
            } else {
                toast.warn('The password and confirmation password do not match.', { theme: 'dark' });
            }

        } else {
            toast.warn('All fields are required!', { theme: 'dark' });
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 text-align-center'>
                    <h3>
                        {reason === "s2up" ? 'Type a new password and switch to new authentication type': 'Reset your password'}
                    </h3>
                </div>
            </div>
            <div className='row'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <p>Type your password:</p>
                    <TextField sx={
                        textFieldSx
                    }
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        autoFocus
                        disabled={loading}
                        type="password"
                        id="password"
                        label=""
                        value={password}
                        variant="outlined"
                        placeholder='Type your password...' />
                    {password && <PasswordStrengthBar password={password} onChangeScore={(value) => {
                        // available values: 0 is too short; 1 is weak; 2 is okay; 3 is good; 4 is strong
                        if (value === 3 || value === 4) {
                            setPasswordIsStrong(true)
                        } else {
                            setPasswordIsStrong(false)
                        }
                    }} />}
                </div>
                <div className='col-4'></div>
            </div>
            <div className='row'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <p>Confirm your password:</p>
                    <TextField sx={
                        textFieldSx
                    }
                        onChange={(event) => {
                            setConfirmPassword(event.target.value)
                        }}
                        disabled={loading}
                        type="password"
                        id="confirm-password"
                        label=""
                        value={confirmPassword}
                        variant="outlined"
                        placeholder='Confirm your password...' />
                </div>
                <div className='col-4'></div>
            </div>
            <div className='row'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <div>
                        <div className='portal-custom-btn' onClick={resetPassword} style={loading ? { pointerEvents: 'none' } : {}}>
                            <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'SAVE'}</span>
                            {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='reset psw' /></span>}
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                </div>
            </div>
        </>
    );
}