import React, { useContext } from 'react';
import { Dashboard } from './pages/Dashboard';
import { NotFound } from './pages/NotFound';

import { Routes, Route } from 'react-router-dom';
import { Idle } from './components/manage-users/partials/Idle'
import { DataContext } from './services/DataProvider.service';
import './App.css';

function App() {
  const dataProvider = useContext(DataContext);
  const auth = dataProvider?.auth.getAuth;

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<NotFound />}>
        </Route>
        <Route
          path=":slug"
          element={
            <Dashboard isLoggedIn={auth} />
          }
        />
      </Routes>
      {auth && <Idle auth={auth}/>}
    </div>
  )
}

export default App;
