import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStyle } from '../../../constants';
import { DataContext } from '../../../services/DataProvider.service';
import { LocalStorage } from '../../../services/LocalStorage.service';

export const  Idle = ({auth}) => {
  const dataProvider = useContext(DataContext);

  // Set timeout values
  const timeout = 1000 * 60 * 30;
  const promptTimeout = 1000 * 30;


  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout)
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);
    logOut()

  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0)
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive
  });

  const handleStillHere = () => {
    setOpen(false);
    activate()
  };

  const handleClose = () => {
    setOpen(false);
};

const logOut = () => {
  LocalStorage.clear();
  dataProvider?.setAuth({
      getAuth: false
  });
  window.location.reload();
  return
};

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted]);


  return (
      <Modal
        open={open && auth}
        onClose={handleClose}
      >
        <Box sx={modalStyle}>
          <h2 className='modal-title'>ATTENTION</h2>
          <p>You will be automatically logged out for security reasons.</p>
          <p>Logging you out in {remaining} seconds</p>
          <div className='portal-custom-modal-btn' onClick={handleStillHere}>
            {"I'm Still Here"}
          </div>
        </Box>
      </Modal>
  )
}
