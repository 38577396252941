import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, GoogleAuthProvider, signInWithPopup, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential } from "firebase/auth";
import { LocalStorage } from '../../services/LocalStorage.service'
import { DataContext } from '../../services/DataProvider.service';
import GoogleIcon from '../../images/GoogleIcon.svg';
import { modalStyle, textFieldSx, circularProgress } from "../../constants";
import AddPlus from '../../images/AddPlus.svg';


export const GoogleLogin = () => {
    const [loading, setLoading] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const dataProvider = useContext(DataContext);
    const { slug } = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const signInWithGoogle = async () => {
        const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            if (!result.user.phoneNumber) {
                toast.warn('A phone number assigned to your account could not be identified. The 2FA operation cannot continue. To enable 2FA, please contact the SOC team.', { theme: 'dark' });
                return
            }
            else {
                setUser(result.user);
                try {
                    setIsVerifying(true);
                    setLoading(false)
                    const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                        'size': 'invisible',
                        'callback': function (response) {
                            console.log("reCAPTCHA solved", response);
                        }
                    },
                        auth);
                    const confirmationResult = await signInWithPhoneNumber(auth, result.user.phoneNumber, appVerifier);
                    setUser({ ...user, confirmationResult });
                    setOpen(true)
                } catch (error) {
                    console.error(error);
                }
            }
        } catch (error) {
            console.log(`ERROR when signing in: ${error}`);
            // log user out
            LocalStorage.clear();
            dataProvider?.setAuth({
                getAuth: false
            });
            // show error message
            toast.error("Error 400 - Could not log you in!");
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode) {
            let verificationError = false;
            const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
            const auth = await getAuth(app);
            try {
                setLoading(true)
                if (user?.confirmationResult?.verificationId) {
                    const credential = await PhoneAuthProvider.credential(
                        user.confirmationResult.verificationId,
                        verificationCode
                    );
                    try{
                        const userCredential = await signInWithCredential(auth, credential);
                        setUser(userCredential);
                    } catch(err){
                        if(err && err.code === 'auth/invalid-verification-code'){
                            toast.warn('Invalid verification code!', { theme: 'dark' });
                            setLoading(false);
                            setVerificationCode('');
                            verificationError = true
                            return
                        }
                    }
                } else {
                    toast.warn('The verification code is not correct!', { theme: 'dark' });
                    verificationError = true;
                    setLoading(false);
                    return
                }
            } catch (error) {
                setLoading(false)
                // Please keep this console log for debugging purposes
                // TODO: Inegrate sentry
                console.log('Error. Something went wrong', error)
                LocalStorage.clear();
            } finally {
                if (!verificationError) {
                    setLoading(false)
                    setIsVerifying(false);
                    const token = await auth.currentUser.getIdToken();
                    LocalStorage.set("user", JSON.stringify(auth.currentUser));
                    LocalStorage.set("token", JSON.stringify(token));
                    dataProvider?.setAuth({
                        getAuth: true
                    });
                    setOpen(false)
                    navigate(`/${slug}`);
                }
            }
        } else {
            toast.warn('Please type the verification code received on your phone!', { theme: 'dark' })
        }
    };


    return (
        <>
            <div className='login-btns login-btns-second' onClick={signInWithGoogle}>
                <img src={GoogleIcon} alt='google-icon' />
                <span>Login with Google</span>
            </div>
            <div className='row' id="recaptcha-container"></div>
            <Modal
                open={open}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        return
                    } else {
                        handleClose()
                    }
                }}
            >
                <Box sx={modalStyle}>
                    {isVerifying && <>
                        <h3>Type the received verification code</h3>
                        <TextField sx={
                            textFieldSx
                        }
                            onChange={(event) => {
                                setVerificationCode(event.target.value);
                            }}
                            autoFocus
                            disabled={loading}
                            type="text"
                            id="verify-code"
                            label=""
                            value={verificationCode}
                            variant="outlined"
                            placeholder='Type the received verification code...' />
                        <div className='portal-custom-btn' onClick={handleVerifyCode} style={loading ? { pointerEvents: 'none' } : {}}>
                            <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'VERIFY CODE'}</span>
                            {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='Verify Code' /></span>}
                        </div>
                    </>
                    }
                </Box>
            </Modal>
        </>
    )
}
