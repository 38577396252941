import Tooltip from "@mui/material/Tooltip";
export const BASE_PORTAL_SERVICE_URL = `${process.env.REACT_APP_SERVICE_URL}`;

// Uncomment to work locally
// export const BASE_PORTAL_API_URL = `${process.env.REACT_APP_LOCAL_URL}:${process.env.REACT_APP_API_PORT}`;

// Uncomment to work on cloud
 export const BASE_PORTAL_API_URL = '';
 export const SEAT_DETAIL_STATUS_ACTIVATING = "ACTIVATING";
 export const SEAT_DETAIL_STATUS_ACTIVATED = "ACTIVATED";
 export const SEAT_DETAIL_STATUS_DEACTIVATING = "DEACTIVATING";
 export const SEAT_DETAIL_STATUS_DEACTIVATED = "DEACTIVATED";

export const tabSx = {
    textTransform: "none",
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.38',
    letterSpacing: 'normal',
    width: '180px'
};

export const circularProgress = {
    '&.MuiCircularProgress-root': {
        height: '30px !important',
        width: '30px !important'
    },
    '.MuiCircularProgress-svg': {
        height: '30px',
        width: '30px',
        color: '#a7d541'
    }
};

export const linearProgressStyle = {
    '&.MuiLinearProgress-root': {
        color:'#a7d541'
    }
};

export const modalStyle = {
    color:'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#25292e !important',
    border: '2px transparent solid',
    animation: 'blink 1s',
    animationIterationCount: 3,
    boxShadow: 24,
    p: 4,
};

export const selectFieldSx = {
    '&.MuiInput-root': {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: '#a7d541',
        }
    },
    "& .MuiSvgIcon-root": {
        color: "white",
    }
}

export const textFieldSx = {
    width: '100%',
    borderColor: 'white',
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#a7d541',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#a7d541',
        },
    },
};

export const teamTabsSx = {
    textTransform: "none",
    borderRight: 1,
    borderColor: 'divider',
    width: '300px',
    backgroundColor: 'rgba(10, 11, 18, 0.38)',
    color: 'white',
    boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.25)',
    '&.MuiTabs-root': {
        width: 'auto'
    },
    '.MuiTabs-indicator': {
        backgroundColor: '#a7d541',
        height: '5px'
    },
    '&.Mui-selected': {
        backgroundColor: 'rgba(10, 11, 18, 0.38)',
        color: 'white',
        alignItems: 'self-start',
        justifyContent: 'flex-start'
    },
    '.MuiButtonBase-root': {
        alignItems: 'self-start',
        justifyContent: 'flex-start'
    }
};

export const dataGridSx = {
    '&.MuiDataGrid-root': {
        borderRadius: '6px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: 'rgba(10, 11, 18, 0.38)',
        color: 'white',
        border: 'solid 1px rgba(255, 255, 255, 0.13)'
    },
    '.MuiDataGrid-row': {
        border: 'solid 1px rgba(255, 255, 255, 0.13)'
    },
    '.MuiDataGrid-cell': {
        borderBottom: 'solid 1px rgba(255, 255, 255, 0.13)'
    },
    '.MuiDataGrid-columnHeaders': {
        borderBottom: 'solid 1px rgba(255, 255, 255, 0.13)'

    },
    '.MuiDataGrid-footerContainer': {
        borderTop: 'solid 1px rgba(255, 255, 255, 0.13)'
    },
    '.MuiTablePagination-displayedRows': {
        color: 'white'
    },
    '.MuiDataGrid-iconSeparator': {
        display: 'none'
    },
    '.MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root': {
        display: 'none'
    }
}

export const columns = [
    { field: 'id', headerName: 'ID', flex: 1, hide: true },
    {
        field: 'name',
        headerName: 'NAME',
        flex: 1,
        editable: false,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
            return (
                <div>
                    <div>
                        {params.value.split(" ").length > 2 ?
                            <div>
                                <span>{params.value.split(' ').slice(0, 2).join(' ')}</span>
                                <Tooltip title={params.value.replace(/^([^ ]+ ){2}/, '')} >
                                    <span className='pending-message'>{params.value.replace(/^([^ ]+ ){2}/, '')}</span>
                                </Tooltip>
                            </div> : params.value}
                    </div>
                </div>
            )
        },
    },
    {
        field: 'status',
        headerName: 'STATUS',
        type: 'number',
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'date',
        headerName: 'DATE',
        type: 'number',
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'plan',
        headerName: 'PLAN',
        type: 'number',
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
];
