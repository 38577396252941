import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { addSeatDetails } from '../../../services/Api.service';
import { validateEmail } from '../../../helpers/validateEmail';
import AddPlus from '../../../images/AddPlus.svg';
import csvFile from './csv-template/Template.csv'
import Papa from 'papaparse';

import { tabSx, textFieldSx, teamTabsSx, selectFieldSx, circularProgress, SEAT_DETAIL_STATUS_DEACTIVATED } from '../../../constants'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={
                {
                    width: 'auto',
                    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
                    backgroundColor: 'rgba(10, 11, 18, 0.38)'
                }
            }
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const YourTeam = ({ seats, portal, slug, callback }) => {

    const plans = seats.referencePlans;
    const account = portal.accountDetails;

    const occupiedSeatsCount = seats.seatDetails.filter(seatDetail => seatDetail.status !== SEAT_DETAIL_STATUS_DEACTIVATED).length;

    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [plan, setPlan] = useState({});
    const [seatAlreadyAdded, setSeatAlreadyAdded] = useState(false);

    const csvFields = {
        firstName: 0,
        lastName: 1,
        email: 2,
        phone: 3,
        plan: 4

    }

    const removeBorder = () => {
        const focusedElement = document.getElementById('add-user');
        focusedElement.className = ''
    }
    const isValidPhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$|^\+[0-9]{11}$/;
        return phoneNumberRegex.test(phoneNumber);
    }

    const parseCsv = (csvString) => {
        const validHeaders = ['First Name','Last Name','Email','Phone Number','Plan'];

        const results = Papa.parse(csvString, { skipEmptyLines: true });
        let invalidIndices = [];
        let validRecords = [];

        results.data.forEach((record, index) => {
            let error = false;

            if(index === 0) {
                if(record.toString() !== validHeaders.toString()) {
                    toast.warn(`Your first csv line: (${record.join(', ')}) should be the headers (${validHeaders.join(', ')}) but it is not. Skipping it!`, { autoClose: false, theme: 'dark' });
                }
                return;
            }

            if(!record || record?.length !== validHeaders.length) {
                const message = `Line ${index} from your csv (${record.join(',')}) should have exactly 5 columns, but instead it has ${record.length}`;
                toast.warn(message, { autoClose: false, theme: 'dark' });
                invalidIndices.push(message);
                return;
            }

            if (!record[csvFields.firstName]) {
                invalidIndices.push(`At line ${index}, first name is missing`);
                error = true;
            }

            if(!record[csvFields.lastName]) {
                invalidIndices.push(`At line ${index}, last name is missing`);
                error = true;
            }

            if(!record[csvFields.email]) {
                invalidIndices.push(`At line ${index}, email is missing`);
                error = true;
            }

            if (!validateEmail(record[csvFields.email])) {
                invalidIndices.push(`At line ${index}, email is not valid`);
                error = true;
            }

            if(record[csvFields.phone] && !isValidPhoneNumber(record[csvFields.phone])) {
                invalidIndices.push(`At line ${index}, phone is present but not valid`);
                error = true;
            }

            if(!record[csvFields.plan]) {
                invalidIndices.push(`At line ${index}, plan is missing`);
                error = true;
            }

            if(!plans.find(plan => plan.vanityName === record[csvFields.plan])) {
                invalidIndices.push(`At line ${index}, the plan ${record[csvFields.plan]} is not a valid plan vanityName`);
                error = true;
            }

            if(!error) {
                validRecords.push(record);
            }
        });
        return { validRecords, invalidIndices };
    }


    const addUser = async () => {
        if (firstName && lastName && email && plan) {
            if(phone && !isValidPhoneNumber(phone)) {
                toast.warn('Phone number is not valid!', { theme: 'dark'});
                return false;
            }

            const planObject = plans.find((obj) => obj.planId === plan);

            if(!planObject?.planId) {
                toast.warn('Selected plan is invalid', { theme: 'dark' });
                return false;
            }

            if (validateEmail(email)) {
                setLoading(true);
                await addSeatDetails({
                    firstName: firstName.replace(/\s+/g, ' ').trim(),
                    lastName: lastName.replace(/\s+/g, ' ').trim(),
                    email: email,
                    workPhone: phone ?? '',
                    planId: planObject.planId,
                    planName: planObject.name,
                    createdBy: account.createdBy,
                    multipleSeats: 0,
                    slug: slug,
                    companyName: account.companyName,
                    atcCompanyId: account.atcCompanyId
                });
                toast.success('Success!', { theme: 'dark' });
                setLoading(false);
                callback();
            } else {
                toast.warn('Email value is not valid!', { theme: 'dark' });
            }

        } else {
            toast.warn('All fields are required!', { theme: 'dark' });
        }
    };

    const onDrop = useCallback(async acceptedFiles => {
        if (acceptedFiles[0].type === "text/csv") {
            setLoading(true);
            let binaryStr = "";
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                binaryStr = reader.result
                console.log(binaryStr);
                let { validRecords, invalidIndices } = parseCsv(binaryStr);

                if(invalidIndices && invalidIndices.length > 0) {
                    toast.warn(`There were some rows that could not be processed:`, { theme: 'dark' });
                    invalidIndices.map(error => {
                        toast.warn(error, { autoClose: false, theme: 'dark'  })
                    });
                }

                if(validRecords.length > 0) {
                    validRecords.forEach((validRecord) => {
                        validRecord[0] = validRecord[0].replace(/\s+/g, ' ').trim();
                        validRecord[1] = validRecord[1].replace(/\s+/g, ' ').trim();
                    })

                    validRecords = validRecords.filter(arraySeat => {
                        const seatAlreadyAdded = seats.seatDetails.find(seat => seat.email === arraySeat[2]);

                        if (seatAlreadyAdded) {
                            toast.warn(`Seat ${arraySeat[2]} already added`, { theme: 'dark' });
                            return false;
                        } 
                        return true;
                      });

                    addSeatDetails({
                        seatDetailsUsers: JSON.stringify(validRecords),
                        plans,
                        createdBy: account.createdBy,
                        multipleSeats: 1,
                        slug: slug,
                        companyName: account.companyName,
                        atcCompanyId: account.atcCompanyId
                    }).then(result => {
                        if (result?.response?.data?.success) {
                            toast.success('Success!', { theme: 'dark' });
                        } else {
                            toast.error("Error!", { theme: 'dark' });
                        }
                    }).catch(e => {
                        toast.error("Error!", { theme: 'dark' });
                    }).then(() => {
                        setTimeout(() => {
                            callback();
                        }, 2000);
                        setLoading(false);
                    });
                } else {
                    toast.warn(`There were ${validRecords.length} valid records in the csv file. Could not process them.`, { theme: 'dark' });
                    setLoading(false);
                    return;
                }
                
            }
            reader.readAsText(acceptedFiles[0]);
        } else {
            toast.warn('Please be sure that the file has .csv extension!', { theme: 'dark' });
            return;
        }
    }, [plans, slug, account.createdBy]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const [value, setValue] = React.useState(0);

    const handleSelectChange = (event) => {
        setPlan(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <p className='partial-title'>Your Team</p>
                </div>
            </div>
            <div className='team-details-section-selected'>
                <span className='team-details-number'>
                    {account.totalSeatsAvailable}
                </span>
                <span className='team-details-title'>
                    Total Seats
            </span>
                <span className='team-details-icon'>
                    <CommentBankOutlinedIcon />
                </span>
            </div>
            <div className='team-details-section'>
                <span className='team-details-number'>
                    {occupiedSeatsCount}
                </span>
                <span className='team-details-title'>
                    Invited
            </span>
                <span className='team-details-icon'>
                    <SendOutlinedIcon />
                </span>
            </div>
            <div className='team-details-section'>
                <span className='team-details-number'>
                    {account.totalSeatsAvailable - occupiedSeatsCount}
                </span>
                <span className='team-details-title'>
                    Seats Available
            </span>
                <span className='team-details-icon'>
                    <PersonAddAltOutlinedIcon />
                </span>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        sx={teamTabsSx}
                        value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={tabSx} label="Add User" {...a11yProps(0)} />
                        <Tab sx={tabSx} label="Upload CSV" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} id='add-user' onClick={removeBorder}>
                    <TextField sx={textFieldSx} required id="fname" label="First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)} variant="standard" />
                    <TextField sx={textFieldSx} required id="lname" label="Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} variant="standard" />
                    <TextField sx={textFieldSx} required id="email" label="Email Address" value={email} onChange={(event) => {
                        if (seats.seatDetails.find(seat => seat.email === event?.target?.value)) {
                            setSeatAlreadyAdded(true);
                            toast.warn('A seat with this address is already added', { theme: 'dark' });
                        } else {
                            setSeatAlreadyAdded(false);
                        }
                        setEmail(event.target.value);
                     } } variant="standard" />

                    <div className='phone-number-form m-b-10'>
                        <p>Phone Number</p>
                        <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            disabled={loading}
                            placeholder="Type phone number..."
                            value={phone}
                            defaultCountry={'US'}
                            onChange={(value) => {
                                setPhone(value)
                            }} />
                    </div>
                    <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                        <InputLabel required id="license-simple-select-standard-label">Plan</InputLabel>
                        <Select
                            sx={selectFieldSx}
                            labelId="license-simple-select-standard-label"
                            id="license-simple-select-standard"
                            value={plan}
                            onChange={handleSelectChange}
                            label="Plan"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {plans.map((singlePlan, index) =>
                                <MenuItem key={index} value={singlePlan.planId}>{singlePlan.vanityName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <div className='portal-custom-btn' onClick={() => {
                        if (!seatAlreadyAdded) {
                            addUser(); 
                        } else {
                            toast.warn('A seat with this address is already added', { theme: 'dark' });
                        }
                    }} style={loading ? { pointerEvents: 'none' } : {}}>
                        <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'Add User'}</span>
                        {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='add user' /></span>}
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <p className='csv-instructions-title'>Instructions</p>
                <p className='csv-instructions-content'>
                You can easily add multiple users by uploading a CSV file:
                </p>
                <p className='csv-instructions-content'>
                (1) Click "Download CSV Template" and fill out the required columns for each user
                </p>
                <p className='csv-instructions-content'>
                (2) Upload the completed CSV below
                </p>
                <p className='csv-instructions-content'>
                (3) Please keep the Header (first line) in the file.
                </p>
                <p className='csv-instructions-content'>
                (4) Phone number is optional. Please refer to the example in the template and remove the example before uploading.
                </p>
                <div className='m-t-20'>
                    <a href={csvFile} className='download-template'>
                        <div className='portal-custom-btn' style={loading ? { pointerEvents: 'none' } : {}}>
                            <span className='portal-custom-btn-text'>{loading ? 'Please wait...' : 'Download CSV Template'}</span>
                            <span className='portal-custom-btn-icon'><FileDownloadOutlinedIcon /></span>
                        </div>
                    </a>
                    <div {...getRootProps()} className='upload-files-zone'>
                        {loading ? <span>Please wait...</span> : <>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop CSV file here ...</p> :
                                    <>
                                        <DriveFolderUploadOutlinedIcon />
                                        <p>Drag & Drop to Upload CSV</p>
                                        <p className='browse-files'>Or Browse files</p>
                                    </>
                            }
                        </>}
                    </div>
                </div>
                </TabPanel>
            </Box>
        </>
    )
}
