import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { LocalStorage } from "../services/LocalStorage.service"
import EmailIcon from '../images/EmailIcon.svg';
import PhoneIconSvg from '../images/PhoneIcon.svg';
import CalendarIcon from '../images/CalendarIcon.svg';
import { ManageUsers } from './manage-users/ManageUsers';
import { Reports } from './reports/Reports3'
import profileImage from '../images/profile-image.jpeg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{
                width: '-webkit-fill-available'

            }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export const LeftMenu = ({ seats, portal, reports, slug, callback }) => {
    const configurations = portal.configurations[0];
    const portalAccount = portal.portalUser[0];
    const account = portal.accountDetails;
    const tabSx = {
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.38',
        letterSpacing: 'normal'
    }
    const [value, setValue] = React.useState(1);

    const getSelectedTab = () => {
        const selection = LocalStorage.get('selectedTab');
        if(selection){
            // Double check for the case when a user disable all reports from mission control and after that refresh the AMP
            if(reports.length < 1){
                LocalStorage.remove('selectedTab')
                setValue(1);
                return
            }
            setValue(parseInt(selection))
        } else {
            setValue(1)
        }
    };

    React.useEffect(() => {
        getSelectedTab()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        LocalStorage.set('selectedTab', newValue)
    };


    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
        >
            {isMobile ?
                <Box sx={{ pb: 7 }}>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                        <BottomNavigation
                            sx={{
                                '&.MuiBottomNavigation-root': {
                                    backgroundColor: '#131421',
                                    boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.15)',
                                }
                            }}
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                        >
                            <BottomNavigationAction label="Account Details" icon={<AccountCircleOutlinedIcon />} />
                            <BottomNavigationAction label="Manage Users" icon={<ManageSearchOutlinedIcon />} />
                        </BottomNavigation>
                    </Paper>
                </Box>
                :
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                        textTransform: "none",
                        borderRight: 1,
                        borderColor: 'divider',
                        width: '340px',
                        backgroundColor: '#11121a',
                        color: 'white',
                        boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.25)',
                        '.MuiTabs-indicator': {
                            backgroundColor: '#a7d541',
                            width: '9px'
                        },
                        '.Mui-selected': {
                            background: '#2a2b33',
                            color: 'white'
                        }
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <div className='ellipse-image-content'>
                            <AccountCircleOutlinedIcon fontSize='large' sx={{
                                width: '1.5em',
                                height: '1.5em'
                            }} />
                        </div>
                        <div className='first-last-name'>
                            {portalAccount.firstName + ' ' + portalAccount.lastName}
                        </div>
                        <div className='company-name-inc'>
                            {account.companyName}
                        </div>
                        <div className='admin-rectangle'>
                            Admin
                         </div>
                    </div>
                    {reports.length > 0 && 
                                        <Tab sx={tabSx} icon={<BarChartOutlinedIcon style={{ color: '#a7d541', marginLeft: '-35px' }} />} iconPosition="start" label={
                                            <div>
                                                <Typography style={{fontWeight: 700, textAlign: 'start'}}>Digital Executive</Typography>
                                                <Typography style={{fontWeight: 700, textAlign: 'start'}}>Protection Report</Typography>
                                            </div>
                                        } {...a11yProps(0)} />
                    }
                    <Tab sx={tabSx} icon={<ManageAccountsOutlinedIcon />} iconPosition="start" label="Manage Users" {...a11yProps()} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0} sx={{
                            padding: 5,
                        }}>
                            <Grid item xs={5}>
                                <Avatar sx={{
                                    width: '50px',
                                    height: '50px',
                                    border: 'solid 1px #a7d541'
                                }}
                                    alt={`${configurations.accountManagerFirstName} ${configurations.accountManagerLastName}`}
                                    src={ profileImage } />
                            </Grid>
                            <Grid item xs={7}>
                                <p className='account-full-name'>{`${configurations.accountManagerFirstName} ${configurations.accountManagerLastName}`}</p>
                                <span className='account-manager'>Account Manager</span>
                            </Grid>
                        </Grid>
                        <div className='horisontal-line'></div>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={PhoneIconSvg} alt='phone-icon' />
                            </Grid>
                            <Grid item xs={10}>
                                <p className='account-contact-details'>
                                    <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        href={`tel:${configurations.accountManagerPhone}`}
                                    >
                                        {configurations.accountManagerPhone}
                                    </a>
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={EmailIcon} alt='email-icon' />
                            </Grid>
                            <Grid item xs={10}>
                                <p className='account-contact-details'>
                                <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        rel="noreferrer"
                                        href={`mailto:${configurations.accountManagerEmail}`}
                                    >
                                        {configurations.accountManagerEmail}
                                    </a>
                                </p>

                            </Grid>
                        </Grid>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={CalendarIcon} alt='calendar-icon' />
                            </Grid>
                            <Grid item xs={10}>
                                <p className='account-contact-details'>
                                    <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        rel="noreferrer"
                                        href={configurations.accountManagerCalendarLink}
                                        target="_blank">
                                        View Calendar
                                </a>
                                </p>
                            </Grid>
                        </Grid>
                    </Box>

                </Tabs>
            }

            <TabPanel value={value} index={0}>
                <div style={{textAlign:'center'}}>
                    <div className='ellipse-image-content'>
                        <AccountCircleOutlinedIcon fontSize='large' sx={{
                            width: '1.5em',
                            height: '1.5em'
                        }} />
                    </div>
                    <div className='first-last-name'>
                    {portalAccount.firstName + ' ' + portalAccount.lastName}
                         </div>
                    <div className='company-name-inc'>
                        {account.companyName}
                        </div>
                    <div className='admin-rectangle'>
                        Admin
                         </div>
                    <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
                        <Grid container spacing={0} sx={{
                            padding: 5,
                        }}>
                            <Grid item xs={5}>
                                <Avatar sx={{
                                    width: '50px',
                                    height: '50px',
                                    border: 'solid 1px #a7d541'
                                }}
                                    alt="Jesse Stevener"
                                    src={profileImage} />
                            </Grid>
                            <Grid item xs={7}>
                                <p className='account-full-name'>{`${configurations.accountManagerFirstName} ${configurations.accountManagerLastName}`}</p>
                                <p className='account-manager'>Account Manager</p>
                            </Grid>
                        </Grid>
                        <div className='horisontal-line'></div>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={PhoneIconSvg} alt='phone-icon' />
                            </Grid>
                            <Grid item xs={10}>
                            <p className='account-contact-details'>
                                    <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        href={`tel:${configurations.accountManagerPhone}`}
                                    >
                                        {configurations.accountManagerPhone}
                                    </a>
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={EmailIcon} alt='email-icon' />
                            </Grid>
                            <Grid item xs={10}>
                            <p className='account-contact-details'>
                                <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        href={`mailto:${configurations.accountManagerEmail}`}
                                    >
                                        {configurations.accountManagerEmail}
                                    </a>
                                </p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} sx={{
                            paddingTop: 1,
                            paddingLeft: 4
                        }}>
                            <Grid item xs={2}>
                                <img src={CalendarIcon} alt='calendar-icon' />
                            </Grid>
                            <Grid item xs={10}>
                            <p className='account-contact-details'>
                                    <a
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'inherit'
                                        }}
                                        rel="noreferrer"
                                        href={configurations.accountManagerCalendarLink}
                                        target="_blank">
                                        View Calendar
                                </a>
                                </p>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </TabPanel>
            <TabPanel value={value} index={reports.length > 0 ? 2 : 1}>
                <ManageUsers seats={seats} portal={portal} slug={slug} callback={callback} />
            </TabPanel>
            {reports.length > 0 &&
                <TabPanel value={value} index={1}>
                    <Reports reports={reports} />
                </TabPanel>
            }

        </Box>
    );
}
