import axios from 'axios';
import { LocalStorage } from '../services/LocalStorage.service';
import { BASE_PORTAL_API_URL } from '../constants';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { toast } from 'react-toastify';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
    axiosApiInstance.interceptors.request.use((request) => {
        request.headers['Authorization'] = 'Bearer ' + JSON.parse(LocalStorage.get('token'));
        return request
    }, async function (error) {
        Promise.reject(error)
    }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403 && !originalRequest._retry) || (error.response.status === 400 && !originalRequest._retry)) {
        originalRequest._retry = true;
        const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
        const auth = await getAuth(app);
        await auth?.currentUser?.getIdTokenResult(true);
        const user = await new Promise((resolve) =>{
            auth.onIdTokenChanged((user) => {
                resolve(user)
            });
        });
        if (user.accessToken) {
            LocalStorage.set('token', JSON.stringify(user.accessToken));
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.stringify(user.accessToken);
            return axiosApiInstance(originalRequest);
        }

    } else {
        return Promise.reject(error);
    }
});

export const getSeatDetails = async (slug) => {
    const config = {
        method: 'get',
        url: `${BASE_PORTAL_API_URL}/${slug}/api/seatDetails`,
        headers: {
            'Content-Type': 'application/json',
            'Slug': slug
        }
    }
    const response = await axiosApiInstance(config);
    return response.data;
};

export const resendEmailIfPossible = async(data) => {
    if (!data.slug || !data.oobCode || !data.purpose) {
        return {
            success: false,
            data: {
                message: `One of the following parameters is missing: slug: ${data.slug}, oobCode: ${data.oobCode}, purpose: ${data.purpose}`
            }
        };
    }
 
    const config = {
        method: 'put',
        url: `${BASE_PORTAL_API_URL}/${data.slug}/api/portalUsers/resendEmail`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    }

    const promisifiedToast = toast.loading('One minute while we validate this activation link!');
    const response = await axiosApiInstance(config).then(() => { 
        toast.update(promisifiedToast, { render: "This activation link has expired, but we are sending you a new one!", type: "success", isLoading: false, autoClose: false, closeOnClick: true });}
    ).catch(() => {
        toast.update(promisifiedToast, { render: "We're sorry, this activation link has expired. The BlackCloak team has been notified and will send another one!", type: "error", isLoading: false, autoClose: false, closeOnClick: true });
    });

    return response?.data;
}

export const removeOobCode = async(data) => {
    if (!data.slug || !data.oobCode) {
        return {
            success: false,
            data: {
                message: `One of the following parameters is missing: slug: ${data.slug}, oobCode: ${data.oobCode}, purpose: ${data.purpose}`
            }
        };
    }

    const config = {
        method: 'put',
        url: `${BASE_PORTAL_API_URL}/${data.slug}/api/portalUsers/removeOobCode`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    }

    const response = await axiosApiInstance(config);

    return response.data;
}

export const addSeatDetails = async (newSeat) => {
    const config = {
        method: 'post',
        url: `${BASE_PORTAL_API_URL}/${newSeat.slug}/api/seatDetails`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: newSeat
    }

    const response = await axiosApiInstance(config);

    return response.data;
};

export const sendCsvSeatDetails = async (formData, slug) => {
    const config = {
        method: 'POST',
        url: `${BASE_PORTAL_API_URL}/${slug}/api/seatDetails`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData
    }

    const response = await axiosApiInstance(config);

    return response.data;
};

export const getPortal = async (slug) => {
    const config = {
        method: 'get',
        url: `${BASE_PORTAL_API_URL}/${slug}/api/portal`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const response = await axiosApiInstance(config);
    return response.data;
};

export const getReports = async (slug) => {
    const config = {
        method: 'get',
        url: `${BASE_PORTAL_API_URL}/${slug}/api/reports`,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const response = await axiosApiInstance(config);
    return response.data;
};

export const deactivateUsers = async (user) => {
    try {
        const config = {
            method: 'put',
            url: `${BASE_PORTAL_API_URL}/${user.slug}/api/seatDetails/deactivating`,
            data: {
                payload: {
                    ...user
                }
            },
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const response = await axiosApiInstance(config);
        return response.data;
    } catch (err) {
        if (err.response.data) {
            return {
                error: err.response.data.message
            }
        }
        return err;
    }
};
