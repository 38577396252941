import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import { deactivateUsers } from '../../../services/Api.service';
import AddPlus from '../../../images/AddPlus.svg';
import { LocalStorage } from '../../../services/LocalStorage.service';
import { formatDate } from '../../../helpers/formatDate';
import { columns, textFieldSx, dataGridSx, modalStyle, linearProgressStyle } from '../../../constants';

export const ActiveUsers = ({ seats, portal, slug, statusCollection }) => {
    const activeSeats =  seats.seatDetails.filter(seat=>{
        if(seat.status === 'ACTIVATED'){
            return true
        }
    });
    const accountManager = portal.configurations[0];
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [errors, setErrors] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [status, setStatus] = useState('Status');
    const [license, setLicense] = useState('Plan');
    const [manageUsers] = useState('Manage Users');
    const [plans, setPlans] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const getTableSelections = (data) => {
        setSelectedRows(data)
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        window.location.reload()
    };

    const deactivateUsersFunction = async () => {
        const errorsArray = [];
        if(selectedRows.length > 0){
            setLoading(true);
            const deactivateAction = selectedRows.map(async (row) => {
                const user = seats.seatDetails.find(seat => seat.seatDetailId === row.seatDetailId);
                const response =  await deactivateUsers({
                    slug: slug,
                    email: user?.email,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    atcCompanyId: portal?.accountDetails?.atcCompanyId,
                    companyName: portal?.accountDetails?.companyName,
                    createdBy: user?.createdBy,
                    createdDate: user?.createdDate,
                    planId: user?.plan?.planId,
                    planName: user?.plan?.name,
                    updatedBy: JSON.parse(LocalStorage.get('user')).uid,
                    seatDetailId: row.seatDetailId
                  })
                  if(response.error){
                    errorsArray.push({
                        message: response.error,
                        user: `${user.firstName} ${user.lastName}`
                    });
                    setErrors(errorsArray)
                  }
            });
            await Promise.allSettled(deactivateAction);
            if(errorsArray.length > 0){
                setLoading(false);
                handleOpen()
            } else {
                setLoading(false);
                // TODO: find a better solution for re-fetch data. Hint -> useContext
                window.location.reload()
            }
        } else {
            toast.warn('Please select users from the table first!', { theme: 'dark' });
        }

    };

    const changeSelectedStatus = (statusItem, users) => {
        if (status === 'all') {
            setUsersData(users);
        } else {
            setStatus(statusItem);
            const results = [];
            users.filter((user) => {
                if (user.status.toLowerCase() === (statusItem.toLowerCase())) {
                    results.push(user);
                }
            })
            setUsersData(results);
        }
    };

    const filterDataByPlan = (plan, users) => {
        const selectedPlanById = plans.find(item=>item.planId === plan)
        if (plan === 'all') {
            setUsersData(users);
            setLicense('all')
        } else {
            setLicense(plan)
            const results = [];
            users.filter((user) => {
                if (user.plan.toLowerCase().includes(selectedPlanById.vanityName.toLowerCase())) {
                    results.push(user);
                }
            })
            setUsersData(results)
        };
    };

    const focusAddUserForm = () => {
        const focusedElement = document.getElementById('add-user');
        focusedElement.scrollIntoView()
        focusedElement.className = 'alerts-border'
    };

    const composeStatus = (status) => {
        if(status === 'ACTIVATING') {
            return 'INVITED'
        } else if(status === 'DEACTIVATING') {
            return 'ACTIVATED'
        } else {
            return status
        }
    };

    const composeName = (status, firstName, lastName) => {
        if(status === 'ACTIVATING') {
            return `${firstName} ${lastName} Pending`
        } else if(status === 'DEACTIVATING') {
            return `${firstName} ${lastName} Deactivation Pending`
        } else {
            return `${firstName} ${lastName}`
        }
    };

    const getTableData = () => {
        const tableData = [];
        seats.seatDetails.forEach(seat => {
            tableData.push({
                id: seat.seatDetailId,
                name: composeName(seat.status, seat.firstName, seat.lastName),
                status: composeStatus(seat.status),
                date: formatDate(seat.createdDate),
                plan: seat.plan?.vanityName || seat?.retiredPlan || 'Unknown'
            });
        });
        setRows(tableData);
        setPlans(seats.referencePlans)
    };

    useEffect(() => {
        getTableData();
        return function cleanup() {
            const focusedElement = document.getElementById('add-user');
            if(focusedElement){
                focusedElement.className = ''
            }
        }
    }, []);

    const filterUsers = (event, users) => {
        const results = [];
        setSearch(event.target.value);
        users.filter((user) => {
            if (user.name.toLowerCase().includes(event.target.value.toLowerCase())) {
                results.push(user);
            }
        })
        setUsersData(results);
    };

    return (
        <div className='row'>
            <div className='row'>
                <div className='col-4'>
                    <p className='partial-title'>Active Users ({activeSeats.length})</p>
                </div>
                <div className='col-3'>
                    <Select
                        label='Manage Users'
                        variant="outlined"
                        sx={{
                            backgroundColor: '#11121a',
                            width: 170,
                            height: 50,
                            border: "1px solid transparent",
                            color: "#fff",
                            "& .MuiSvgIcon-root": {
                                color: "white",
                                borderLeft: '3px solid #25292E'
                            }
                        }}
                        value={manageUsers}
                    >
                        <MenuItem value={"Manage Users"}>Manage Users</MenuItem >
                        <MenuItem value={"manageusers"}>
                            <div className='portal-custom-btn-dropdown' onClick={focusAddUserForm}>
                                <span className='portal-custom-btn-text'>Add Users</span>
                                <span className='portal-custom-btn-icon'><img src={AddPlus} alt='add users' /></span>
                            </div>
                        </MenuItem>
                        <MenuItem value={"deactivateusers"}>
                            <div className='delete-user-btn-dropdown' onClick={deactivateUsersFunction}>
                                <span className='delete-user-btn-text'>Deactivate Users</span>
                            </div>
                        </MenuItem>
                    </Select>
                </div>
            </div>
            <div className='users-filters'>
                <TextField sx={
                    textFieldSx
                }
                    onChange={(event) => {
                        filterUsers(event, rows)
                    }}
                    id="name"
                    label=""
                    value={search}
                    variant="outlined"
                    placeholder='Search users' />
                <Select
                    label='Status'
                    variant="outlined"
                    sx={{
                        marginTop: '25px',
                        borderRadius: '25px',
                        backgroundColor: 'transparent',
                        width: 100,
                        height: 35,
                        border: "1px solid white",
                        color: "#fff",
                        "& .MuiSvgIcon-root": {
                            color: "white",
                        }
                    }}
                    value={status}
                    onChange={(e) => changeSelectedStatus(e.target.value, rows)}
                >
                    <MenuItem value={"Status"}>Status</MenuItem >
                    <MenuItem value={"All"}>All</MenuItem>
                    {statusCollection.map((status, index) =>
                        <MenuItem key={index} value={status}>{status}</MenuItem>
                    )}
                </Select>
                <Select
                    variant="outlined"
                    sx={{
                        marginTop: '25px',
                        marginLeft: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        width: 150,
                        height: 35,
                        border: "1px solid white",
                        color: "#fff",
                        "& .MuiSvgIcon-root": {
                            color: "black",
                        },
                        '& .MuiOutlinedInput-input': {
                            color: 'black !important'
                        }
                    }}
                    value={license}
                    onChange={(e) => filterDataByPlan(e.target.value, rows)}
                >
                    <MenuItem value={"Plan"}>Plan</MenuItem>
                    <MenuItem value={"all"}>All</MenuItem>
                    {plans.map((plan, index) =>
                        <MenuItem key={index} value={plan.planId}>{plan.vanityName}</MenuItem>
                    )}
                </Select>
            </div>
            <Box sx={isMobile ? { height: 495, width: 'auto', paddingBottom:'30px' } : { height: 495, width: 'auto' }}>
            {loading && <LinearProgress color="inherit" sx={
                linearProgressStyle
            }/>}
                <DataGrid
                    rows={usersData.length > 0 ? usersData : rows || []}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    sx={dataGridSx}
                    onSelectionModelChange={(items) => {
                        const userToDeactivate =  seats.seatDetails.filter(row=>{
                            if(items.includes(row.seatDetailId)){
                                return true
                            }
                        })
                        getTableSelections(userToDeactivate)
                      }}

                    hideFooterPagination
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    {errors.length > 0 && <>
                    <h2 className='modal-title'>ATTENTION</h2>
                        <p><b>There is at least one user whose status is still pending activation and cannot be deactivated.</b></p>
                        <p><b>The following users could not be deactivated:</b></p>
                        {errors.map((error, index) =>
                            <span key={index}>{`${error.user}, `}</span>
                        )}
                    </>}
                    <p>Please contact your account manager for assistance.</p>
                    <p><b>Account manager:</b></p>
                    <p>Name: {`${accountManager.accountManagerFirstName} ${accountManager.accountManagerLastName}`}</p>
                    <p>E-mail: {accountManager.accountManagerEmail}</p>
                    <p>Phone: {accountManager.accountManagerPhone}</p>
                    <div className='portal-custom-modal-btn' onClick={handleClose}>
                        {'CLOSE'}
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
