import React, { useState, useEffect } from 'react';
import "@fontsource/montserrat";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DevicesProtected from '../../images/DevicesProtected.svg';
import HomesRegistered from '../../images/HomesRegistered.svg';
import HomePenTests from '../../images/HomePenTests.svg';
import PasswordExposed from '../../images/PasswordsExposed.svg';
import RecordsPending from '../../images/RecordsPending.svg';
import RecordsFound from '../../images/RecordsFound.svg';
import RecordsRemoved from '../../images/RecordsRemoved.svg';
import EmailsMonitored from '../../images/EmailsMonitored.svg';
import InboundInquiries from '../../images/InboundInquiries.svg';
import CyberEvents from '../../images/CyberEvents.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import { Bar } from "./partials/Bar";
import { formatNumber } from "../../helpers/thousandsSeparator";


import { circularProgress } from '../../constants';
export const Reports = ({ reports }) => {
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(reports[0].generated_at);
    const [selectedReport, setSelectedReport] = useState(reports[0]);
    const [chartsData, setChartsData] = useState([])
    const downloadReport = async () => {
        setLoading(true)
        const data = document.getElementById('report-dwnld');

        html2canvas(data, { backgroundColor: 'rgb(48, 53, 71)' }).then((canvas) => {
            // Few necessary setting options
            const imgWidth = 208;
            const pageHeight = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            // const heightLeft = imgHeight; -> keep this commented code (Maybe we need this in the future.)

            const contentDataURL = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

            // start Footer
            const d = new Date();
            const year = d.getFullYear();
            const footerText = `© ${year}. BlackCloak, INC. - Download date: ${d.toLocaleString()}`
            pdf.text(footerText, imgWidth / 2 - (pdf.getTextWidth(footerText) / 2), pageHeight - 15, { baseline: 'bottom' });
            // end Footer
            const position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save(`report-${d.toLocaleString()}.pdf`); // Generated PDF
            setLoading(false)
        });
    };

    const getFirstAndLastDayOfMonth = (datetimeString) => {
        const datetime = new Date(datetimeString);
        const year = datetime.getFullYear();
        const month = datetime.getMonth() + 1; // January is month 0 in JavaScript

        const getFormattedDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const firstDayOfMonth = new Date(year, month - 1, 1);
        const formattedFirstDay = getFormattedDate(firstDayOfMonth);

        const lastDayOfMonth = new Date(year, month, 0);
        const formattedLastDay = getFormattedDate(lastDayOfMonth);

        return [formattedFirstDay, formattedLastDay];
    };

    const computeReportData = (selectedDate) => {
        const charts = []
        reports.filter((report) => {
            if (report.generated_at === selectedDate) {
                setSelectedReport(report)
                // Get selected report index
                const selectedReportIndex = reports.indexOf(report);
                // Check if there is a previous report for this selection
                if (reports[selectedReportIndex + 1]) {
                    charts.push(report);
                    charts.push(reports[selectedReportIndex + 1]);
                    setChartsData(charts);
                } else {
                    ;
                    charts.push(report);
                    setChartsData(charts);
                }
            }
        })
    };

    const getChartData = (indetifier) => {
        const data = [];
        if (chartsData.length === 1) {
            //Default values applied for last report that does not have something to compare with.
            data.push({
                index: 0,
                value: 0
            });
            data.push({
                index: 1,
                value: chartsData[0][indetifier]
            })
        } else {
            chartsData.forEach((item, index) => {
                data.push({
                    index,
                    value: item[indetifier]
                })
            })
            data.reverse()
        }
        return data
    };

    useEffect(() => {
        if (selectedDate) {
            computeReportData(selectedDate)
        }
    }, [selectedDate]);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={5}>
                    <p className='partial-title'>Digital Executive Protection Report</p>
                </Grid>
                <Grid item xs={3}>
                    <div className='portal-custom-btn' onClick={downloadReport} style={loading ? { pointerEvents: 'none' } : {}}>
                        <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'Download PDF report'}</span>
                        {!loading && <span className='portal-custom-btn-icon'><PictureAsPdfIcon /></span>}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className='float-right m-t-10'>
                        <Select
                            label='Date Range'
                            variant="outlined"
                            sx={{
                                backgroundColor: '#11121a',
                                minWidth: 400,
                                height: 55,
                                border: "1px solid transparent",
                                color: "#fff",
                                "& .MuiSvgIcon-root": {
                                    color: "white",
                                }
                            }}
                            value={selectedDate}
                            onChange={(selection) => {
                                setSelectedDate(selection.target.value);
                            }}
                        >
                            {reports.map((report, index) => {
                                const [firstDay, lastDay] = getFirstAndLastDayOfMonth(report.generated_at);
                                return (<MenuItem key={index} value={report.generated_at}>Date Range: {firstDay} - {lastDay}</MenuItem>)
                            })}
                        </Select>
                    </div>
                </Grid>
            </Grid>
            <div id='report-dwnld'>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <p className='partial-subtitle'>Utilization</p>
                    </Grid>
                    <Grid item xs={0.4} ></Grid>
                    <Grid item xs={7.6}>
                        <p className='partial-subtitle'>Individual Privacy</p>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={4} className='report-container'>
                        <p className='report-small-title  padding-top-20 padding-left-30'><strong className='white'>Executives Protected</strong></p>
                        <span style={{ display: 'block', textAlign: 'center', fontWeight: 'bolder' }}>Total: {selectedReport.contact_information}</span>
                        <div style={{
                            transform: 'rotate(-90deg)',
                            minWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100%'
                        }}>
                            <Bar minValue={chartsData && getChartData('protected')} total={selectedReport.contact_information} />
                        </div>
                    </Grid>
                    <Grid item xs={0.4} ></Grid>
                    <Grid item xs={7.6} className=''>
                        <Grid item xs={12} className='report-container'>
                            <p className='report-small-title padding-top-20 padding-left-30 padding-right-30'><strong className='white'>Executive PII Risk</strong></p>
                            <Grid container spacing={0} className='border-bottom padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={RecordsFound} alt='records-found' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Records found</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.pii_records_found)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className='border-bottom padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={RecordsRemoved} alt='records-removed' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Records Removed</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.pii_records_removed)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className='padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={RecordsPending} alt='records-pending' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Records Pending</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.pii_records_pending)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid item xs={12} className='report-container m-t-30'>
                            <p className='report-small-title padding-top-20 padding-left-30 padding-right-30'><strong className='white'>Breached Personal Data</strong></p>
                            <Grid container spacing={0} className='border-bottom padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={EmailsMonitored} alt='emails-monitored' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Emails Monitored</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.personal_emails_monitored)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className='border-bottom padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={CyberEvents} alt='data-breach-events' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Data Breach Events</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.personal_data_breach_events)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} className='padding-top-10 padding-left-30 padding-right-30'>
                                <Grid item xs={1}>
                                    <div className='icons-container'>
                                        <img className='report-icons m-t-10' src={PasswordExposed} alt='password-exposed' />
                                    </div>
                                </Grid>
                                <Grid item xs={7}>
                                    <p className='report-small-title m-t-10'>Password Exposed</p>
                                </Grid>
                                <Grid item xs={4} className='reports-details-number'>
                                    {formatNumber(selectedReport.personal_passwords_exposed)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className='m-t-30'>
                    <Grid item xs={9} className=''>
                        <Grid container spacing={0}>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='partial-subtitle'>Protected Homes and Devices</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid container spacing={1} className='report-container'>
                            <Grid item xs={4} className='border-right'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='icons-container'>
                                            <img className='report-icons m-t-10' src={HomePenTests} alt='home-pen-tests' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row team-details-number'>
                                    <div className='col-12'>{formatNumber(selectedReport.ph_home_pentests)}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 report-small-title'>
                                        Home Pen-Tests
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className='border-right'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='icons-container'>
                                            <img className='report-icons m-t-10' src={HomesRegistered} alt='homes-registered' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row team-details-number'>
                                    <div className='col-12'>{formatNumber(selectedReport.ph_homes_registered)}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 report-small-title'>
                                        Homes Registered
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4} className=''>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='icons-container'>
                                            <img className='report-icons m-t-10' src={DevicesProtected} alt='devices-protected' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row team-details-number'>
                                    <div className='col-12'>{formatNumber(selectedReport.pd_devices_protected)}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 report-small-title'>
                                        Devices Protected
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0.4} className='align-center'></Grid>
                    <Grid item xs={2.6} className=''>
                        <Grid container spacing={0}>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='partial-subtitle'>Concierge Sessions</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid container spacing={1} className='report-container'>
                            <Grid item xs={12} className=''>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='icons-container'>
                                            <img className='report-icons m-t-10' src={InboundInquiries} alt='inbound-inquiries' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row team-details-number'>
                                    <div className='col-12'>{formatNumber(selectedReport.cs_inbound_inquiries)}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 report-small-title'>
                                        Inbound Inquiries
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
