import React, { createContext, useState } from "react";
import { isLoggedIn } from "../services/IsLoggedIn.service";

export const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [auth, setAuth] = useState({ getAuth: isLoggedIn() });

  return (
    <>
      <DataContext.Provider
        value={{
          auth,
          setAuth
        }}
      >
        {children}
      </DataContext.Provider>
    </>
  );
};
