import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { LeftMenu } from '../components/LeftMenu';
import { Loader } from '../components/Loader';
import { NotFound } from '../pages/NotFound';
import { getSeatDetails, getPortal, getReports } from '../services/Api.service';
import { Login } from './Login';
import { EnrollUsernameAndPassword2Fa } from './EnrollUsernameAndPassword2Fa';
import { ResetUserPassword } from './ResetUserPassword';
import { EnrollGoogle2Fa } from './EnrollGoogle2Fa';
import { LocalStorage } from "../services/LocalStorage.service";
import { DataContext } from '../services/DataProvider.service';
import { toast } from 'react-toastify'

export const Dashboard = ({ isLoggedIn }) => {
    const [loading, setLoading] = useState(true);
    const [seatsData, setSeatsData] = useState({});
    const [portalData, setPortalData] = useState({});
    const [ portalReports, setPortalReports ] = useState([]);
    const [actionParam, setActionParam] = useState('')
    const { slug } = useParams();
    const dataProvider = useContext(DataContext);

    const logOut = () => {
        LocalStorage.clear();
        dataProvider?.setAuth({
            getAuth: false
        });
    };

    const getAllData = async () => {
        if (slug !== "accountSettings") {
            setLoading(true);
            let seats;
            let message;
            try {
                // this is the first request that ever gets made, we use the catch block to prevent infinite loading
                seats = await getSeatDetails(slug);
            } catch (e) {
                switch (e.response.status) {
                    case 404:
                        message = 'ERROR 100 - this action is not allowed!';
                        console.error(message);
                        toast.error(message);
                        break;
                    case 401:
                        message = 'ERROR 200 - this action is not allowed!';
                        console.error(message);
                        toast.error(message);
                        break;
                    default:
                        message = 'ERROR 300 - Internal error, please try again later!';
                        console.error(message);
                        toast.error(message);
                        break;
                }
                logOut();
            }
            setSeatsData(seats);
            const portal = await getPortal(slug);
             const repData = await getReports(slug);
             setPortalReports(repData.reports);
            if (portal.errors.length > 0) {
                toast.error("There were some errors when loading the portal, see them below:");
                for (let i = 0; i < portal.errors.length; i++) {
                    console.error(portal.errors[i]);
                    toast.error(portal.errors[i]);
                }
            }
            setPortalData(portal);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            getAllData()
        }
        if(!actionParam){
            const url = window.location.search
            let params = new URLSearchParams(url);
            const purpose = params.getAll('purpose')[0];
            purpose && setActionParam(purpose)
        }

    }, [isLoggedIn, actionParam]);

    return (
        <div>
            {
                slug === "accountSettings" ? <>
                    {actionParam ? <>
                    {actionParam === '2faup' && <EnrollUsernameAndPassword2Fa />}
                    {actionParam === 'rpup' && <ResetUserPassword reason={'rpup'}/>}
                    {actionParam === 's2up' && <ResetUserPassword reason={'s2up'}/>}
                    {actionParam === '2fag' && <EnrollGoogle2Fa/>}
                    {actionParam !== 's2up' && actionParam !== '2faup' && actionParam !== 'rpup' && actionParam !== '2fag' && <NotFound/>}
                    </>: <>
                    {<NotFound/>}
                    </>}
                </> :
                    <div>
                        {isLoggedIn ? <>
                            {loading ? <Loader />
                                :
                                <>
                                    <ResponsiveAppBar portal={portalData} slug={slug} />
                                    <LeftMenu seats={seatsData} portal={portalData} reports={portalReports} slug={slug} callback={getAllData} />
                                </>}

                        </>
                            :
                            <Login />
                        }
                    </div>
            }
        </div>
    )
}
