import React from 'react';
import { GoogleLogin } from "../components/login/GoogleLogin";
import { isMobile } from 'react-device-detect';
import logo from '../images/AccountCenterLogo.svg';

import { UsernamePasswordLogin } from "../components/login/UsernamePasswordLogin";

export const Login = () => {

    return (
        <div className='login-screen'>
            <img style={isMobile ? {width:'auto'} : {}} className='login-screen-logo' src={logo} alt='logo' />
            <GoogleLogin/>
        <UsernamePasswordLogin/>
        </div>
    )
}
