import React from 'react';
import { isMobile } from 'react-device-detect';
import logo from '../images/AccountCenterLogo.svg';
export const  NotFound = () => {
    return (
      <div className='not-found'>
      <img style={isMobile ? {width:'auto'} : {}} className='login-screen-logo' src={logo} alt='logo' />
        <h2 className='bolder'>404 Page not found</h2>
      </div>
    );
  }