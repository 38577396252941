import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { LocalStorage } from '../../services/LocalStorage.service'
import { DataContext } from '../../services/DataProvider.service';
import EmailIcon from '../../images/EmailIcon.svg';
import AddPlus from '../../images/AddPlus.svg';
import { modalStyle, textFieldSx, circularProgress } from "../../constants";

export const UsernamePasswordLogin = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isVerifying, setIsVerifying] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const dataProvider = useContext(DataContext);
    const { slug } = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleLoginWithTFA = async () => {
        if (email && password) {
            const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
            const auth = await getAuth(app);
            try {
                const details = await signInWithEmailAndPassword(auth, email, password)
                if (details?.user?.phoneNumber) {
                    setUser(details.user)
                    try {
                        setIsVerifying(true);
                        setLoading(false)
                        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                            'size': 'invisible',
                            'callback': function (response) {
                                console.log("reCAPTCHA solved", response);
                            }
                        },
                            auth);
                        const confirmationResult = await signInWithPhoneNumber(auth, details.user.phoneNumber, appVerifier);
                        setUser({ ...user, confirmationResult });
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    toast.warn('A phone number assigned to your account could not be identified. The 2FA operation cannot continue. To enable 2FA, please contact the SOC team.', { theme: 'dark' });
                    return
                }
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        //Generic message
                        toast.warn('Username or password did not match!', { theme: 'dark' })
                        // To be discuss if we want a specific error message for each case
                        //toast.warn('Wrong Password! Please try again!', { theme: 'dark' });
                        break;
                    case 'auth/user-not-found':
                        //Generic message
                        toast.warn('Username or password did not match!', { theme: 'dark' })
                        // To be discuss if we want a specific error message for each case
                        //toast.warn('User not found! Check if the Email/Password is written correctly!', { theme: 'dark' });
                        break;
                    default:
                        // Please keep this console log for debugging purposes
                        // TODO: Inegrate sentry
                        console.log('Error. Something went wrong', error)
                }
            }
        } else {
            toast.warn('All fields are required!', { theme: 'dark' });
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode) {
            let verificationError = false;
            const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
            const auth = await getAuth(app);
            try {
                setLoading(true);
                if (user?.confirmationResult?.verificationId) {
                    const credential = await PhoneAuthProvider.credential(
                        user.confirmationResult.verificationId,
                        verificationCode
                    );
                    try {
                        const userCredential = await signInWithCredential(auth, credential);
                        setUser(userCredential);
                    } catch(err){
                        if(err && err.code === 'auth/invalid-verification-code'){
                            toast.warn('Invalid verification code!', { theme: 'dark' });
                            setLoading(false);
                            setVerificationCode('');
                            verificationError = true;
                        }
                    }
                } else {
                    toast.warn('The verification code is not correct!', { theme: 'dark' });
                    verificationError = true;
                    setLoading(false);
                    return
                }
            } catch (error) {
                setLoading(false);
                // Please keep this console log for debugging purposes
                // TODO: Inegrate sentry
                console.log('Error. Something went wrong', error);
                LocalStorage.clear();
            } finally {
                if (!verificationError) {
                    setLoading(false);
                    setIsVerifying(false);
                    const token = await auth.currentUser.getIdToken();
                    LocalStorage.set("user", JSON.stringify(auth.currentUser));
                    LocalStorage.set("token", JSON.stringify(token));
                    dataProvider?.setAuth({
                        getAuth: true
                    });
                    navigate(`/${slug}`);
                }
            }
        } else {
            toast.warn('Please type the verification code received on your phone!', { theme: 'dark' });
        }
    };

    return (
        <>
            <div className='login-btns' onClick={() => setOpen(true)}>
                <img src={EmailIcon} alt='usr-psw-icon' />
                <span>Login email & password</span>
            </div>
            <Modal
                open={open}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        return
                    } else {
                        handleClose()
                    }
                }}
            >
                <Box sx={modalStyle}>
                    {isVerifying ? <>
                        <h3>Type the received verification code</h3>
                        <TextField sx={
                            textFieldSx
                        }
                            onChange={(event) => {
                                setVerificationCode(event.target.value);
                            }}
                            autoFocus
                            disabled={loading}
                            type="text"
                            id="verify-code"
                            label=""
                            value={verificationCode}
                            variant="outlined"
                            placeholder='Type the received verification code...' />
                        <div className='portal-custom-btn' onClick={handleVerifyCode} style={loading ? { pointerEvents: 'none' } : {}}>
                            <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'VERIFY CODE'}</span>
                            {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='Verify Code' /></span>}
                        </div>
                    </> :
                        <>
                            <h3>Username & Password 2FA</h3>
                            <p>Username(email):</p>
                            <TextField sx={
                                textFieldSx
                            }
                                autoFocus
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                disabled={loading}
                                type="text"
                                id="verify-email"
                                label=""
                                value={email}
                                variant="outlined"
                                placeholder='Username(email)...' />
                            <p>Password:</p>
                            <TextField sx={
                                textFieldSx
                            }
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                                disabled={loading}
                                type="password"
                                id="verify-password"
                                label=""
                                value={password}
                                variant="outlined"
                                placeholder='Password...' />
                            <div className='portal-custom-modal-btn m-t-20' onClick={handleLoginWithTFA}>
                                {'LOGIN'}
                            </div>
                            <div className='portal-custom-modal-btn' onClick={handleClose}>
                                {'CLOSE'}
                            </div>
                        </>
                    }
                </Box>
            </Modal>
            <div className='row' id="recaptcha-container"></div>
        </>
    )
}
