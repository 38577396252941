import React from "react";
import Tooltip from '@mui/material/Tooltip';
import ProgressBar from "@ramonak/react-progress-bar";
import './Bar.css';

export const Bar = ({ minValue, total = 100, isVertical = true }) => {
    return <>
        <Tooltip title={(minValue[0]?.value ? minValue[0]?.value.toString() : minValue[1]?.value.toString()) || ''} placement="top">
            <div>
                <ProgressBar
                    animateOnRender={true}
                    completed={(minValue[0]?.value ? minValue[0]?.value.toString() : minValue[1]?.value.toString()) || 0}
                    maxCompleted={total}
                    className={isVertical ? "wrapper-vertical" : "wrapper"}
                    barContainerClassName={isVertical ? "container-vertical" : "container"}
                    labelClassName={isVertical ? "label-vertical" : "label"}
                />
            </div>
        </Tooltip>
    </>;
};
