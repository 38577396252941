import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-number-input'
import { getAuth, GoogleAuthProvider, signInWithPopup, RecaptchaVerifier, PhoneAuthProvider, signInWithPhoneNumber, linkWithCredential } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { LocalStorage } from '../services/LocalStorage.service'
import GoogleIcon from '../images/GoogleIcon.svg';
import AddPlus from '../images/AddPlus.svg';
import { DataContext } from '../services/DataProvider.service';
import { textFieldSx, circularProgress } from '../constants';

export const EnrollGoogle2Fa = () => {
    const dataProvider = useContext(DataContext);
    const [user, setUser] = useState(null);
    const [displayPhoneInput, setDisplayPhoneInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [verificationCode, setVerificationCode] = useState("");
    const [authentication, setAuthentication] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [allStepsDone, setAllStepsDone] = useState(false);

    const enrollWithGoogle2Fa = async () => {
        const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
        const auth = getAuth(app);
        setAuthentication(auth)
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            if (!auth.currentUser.phoneNumber) {
                setDisplayPhoneInput(true);
                setUser(auth.currentUser)
            } else {
                toast.warn('User already enrolled!', { theme: 'dark' });
            }
        } catch (error) {
            console.log(`ERROR when signing in: ${error}`);
            //clear login user data
            LocalStorage.clear();
            dataProvider?.setAuth({
                getAuth: false
            });
            // show error message
            toast.error("Error 400 - Could not log you in!");
        }
    };

    const sendVerificationCode = async () => {
        setLoading(true);
        try {
            setIsVerifying(true);
            setLoading(false)
            const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                    console.log("reCAPTCHA solved", response);
                }
            },
                authentication);
            const confirmationResult = await signInWithPhoneNumber(authentication, phone, appVerifier);
            setUser({ ...user, confirmationResult });
        } catch (error) {
            // Please keep this console log for debugging purposes
            // TODO: Inegrate sentry
            console.log('Error. Something went wrong', error)
        }
    };

    const checkVerificationCode = async () => {
        if (verificationCode) {
            let credentialErrorCode = false;
            let verificationError = false;
            try {
                setLoading(true)
                if (user?.confirmationResult?.verificationId) {
                    const credential = await PhoneAuthProvider.credential(
                        user.confirmationResult.verificationId,
                        verificationCode
                    );
                    try {
                        const newUser = await linkWithCredential(authentication.currentUser, credential);
                        setUser(newUser);
                    } catch (credentialErr) {
                        if(credentialErr.code === "auth/invalid-verification-code"){
                            toast.warn('Invalid verification code!', { theme: 'dark' });
                            setLoading(false);
                            setVerificationCode('');
                            verificationError = true
                            return
                        }
                        if (credentialErr.code === "auth/account-exists-with-different-credential") {
                            credentialErrorCode = true;
                        }
                    }
                } else {
                    toast.warn('The verification code is not correct!', { theme: 'dark' });
                    verificationError = true;
                    setLoading(false);
                    return
                }
            } catch (error) {
                setLoading(false)
                // Please keep this console log for debugging purposes
                // TODO: Inegrate sentry
                console.log('Error. Something went wrong', error)
            } finally {
                if (credentialErrorCode) {
                    toast.warn('This phone number was already associated with another account! Please try to use another phone number.', { theme: 'dark' })
                    setLoading(false);
                    setIsVerifying(false);
                    LocalStorage.clear();
                    authentication.signOut();
                    setAllStepsDone(false)
                } else {
                    if (!verificationError) {
                        setLoading(false)
                        setIsVerifying(false);
                        LocalStorage.clear();
                        authentication.signOut();
                        setAllStepsDone(true);
                        //Redirect to portal page.
                        const url = window.location.search
                        let params = new URLSearchParams(url);
                        const gotolocation = params.getAll('gotolocation')[0];
                        // Check if we have gotolocation url param. If not, display the default 'You are all set!' message.
                        if (gotolocation) {
                            window.location.replace(gotolocation);
                        }
                    }
                }
            }
        } else {
            toast.warn('Please type the verification code received on your phone!', { theme: 'dark' })
        }
    }

    return (
        <>
            {allStepsDone ? <>
                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4'>
                        <h3>
                            You are all set!
                        </h3>
                        <p>You will be redirected to the login page of the portal.</p>
                    </div>
                    <div className='col-4'></div>
                </div>
            </> :
                <>
                    {isVerifying ? <>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-4'>
                                <p>Type the received verification code</p>
                                <TextField sx={
                                    textFieldSx
                                }
                                    onChange={(event) => {
                                        setVerificationCode(event.target.value);
                                    }}
                                    autoFocus
                                    disabled={loading}
                                    type="text"
                                    id="verify-code"
                                    label=""
                                    value={verificationCode}
                                    variant="outlined"
                                    placeholder='Type the received verification code...' />
                            </div>
                            <div className='col-4'></div>
                        </div>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-4'>
                                <div>
                                    <div className='portal-custom-btn' onClick={checkVerificationCode} style={loading ? { pointerEvents: 'none' } : {}}>
                                        <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'Check verification SMS code'}</span>
                                        {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='check verification code' /></span>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                            </div>
                        </div>
                    </> : <>
                            {displayPhoneInput ? <>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <p>Type your phone number:</p>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            disabled={loading}
                                            placeholder="Type your phone number..."
                                            value={phone}
                                            defaultCountry={'US'}
                                            onChange={(value) => {
                                                setPhone(value)
                                            }} />
                                    </div>
                                    <div className='col-4'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <div>
                                            <div className='portal-custom-btn' onClick={sendVerificationCode} style={loading ? { pointerEvents: 'none' } : {}}>
                                                <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'Get SMS verification code'}</span>
                                                {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='send verification code' /></span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                    </div>
                                </div>
                            </> :
                                <div className='login-btns' onClick={enrollWithGoogle2Fa}>
                                    <img src={GoogleIcon} alt='google-icon' />
                                    <span>Enroll with Google 2FA</span>
                                </div>
                            }
                        </>}
                </>
            }
            <div className='row' id="recaptcha-container"></div>
        </>
    );
}
