import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import {
    getAuth,
    verifyPasswordResetCode,
    confirmPasswordReset,
    signInWithEmailAndPassword,
    RecaptchaVerifier,
    PhoneAuthProvider,
    signInWithPhoneNumber,
    linkWithCredential
} from "firebase/auth";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input'
import PasswordStrengthBar from 'react-password-strength-bar';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalStorage } from '../services/LocalStorage.service'
import AddPlus from '../images/AddPlus.svg';
import { textFieldSx, circularProgress } from '../constants';
import { resendEmailIfPossible, removeOobCode } from '../services/Api.service';
import urlHelper from '../helpers/urlHelper';



export const EnrollUsernameAndPassword2Fa = () => {

    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [user, setUser] = useState(null);
    const [verificationCode, setVerificationCode] = useState("");
    const [isVerifying, setIsVerifying] = useState(false);
    const [allStepsDone, setAllStepsDone] = useState(false);
    const [passwordIsStrong, setPasswordIsStrong] = useState(false);

    const resetPassword = async () => {
        if (password && confirmPassword && phone) {
            if (password === confirmPassword) {
                if (!passwordIsStrong) {
                    toast.warn('Your password is not strong enough! The password must be at least six characters and contain at least one letter, one number and a symbol!', { theme: 'dark' });
                    return;
                }
                setLoading(true);
                const url = window.location.search;
                const { oobCode, purpose, gotolocation } = urlHelper.extractParams(url, ['oobCode', 'purpose', 'gotolocation']);
                const slug = urlHelper.extractPathName(gotolocation);

                const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
                const auth = await getAuth(app);
                try {
                    const email = await verifyPasswordResetCode(auth, oobCode)
                    if (email) {
                        await confirmPasswordReset(auth, oobCode, password);
                        const details = await signInWithEmailAndPassword(auth, email, password)

                        setUser(details.user);
                        try {
                            setIsVerifying(true);
                            setLoading(false)
                            const appVerifier = new RecaptchaVerifier('recaptcha-container', {
                                'size': 'invisible',
                                'callback': function (response) {
                                    console.log("reCAPTCHA solved", response);
                                }
                            },
                                auth);
                            const confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
                            removeOobCode({slug, oobCode});
                            setUser({ ...user, confirmationResult });
                        } catch (error) {
                            // Please keep this console log for debugging purposes
                            // TODO: Inegrate sentry
                            console.log('Error. Something went wrong', error)
                        }

                    }
                } catch (err) {
                    // TODO: Inegrate sentry
                    toast.error('Error: Invalid or expired action code.', { theme: 'dark' });
                    setLoading(false);
                    await resendEmailIfPossible({
                        oobCode,
                        purpose,
                        slug
                    });
                }
            } else {
                toast.warn('The password and confirmation password do not match.', { theme: 'dark' });
            }
        } else {
            toast.warn('All fields are required!', { theme: 'dark' });
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode) {
            const app = await initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
            const auth = await getAuth(app);
            let credentialErrorCode = false;
            let verificationError = false;
            try {
                setLoading(true)
                if (user?.confirmationResult?.verificationId) {
                    const credential = await PhoneAuthProvider.credential(
                        user.confirmationResult.verificationId,
                        verificationCode
                    );
                    try {
                        const newUser = await linkWithCredential(auth.currentUser, credential);
                        setUser(newUser);
                    } catch (credentialErr) {
                        if(credentialErr.code === "auth/invalid-verification-code"){
                            toast.warn('Invalid verification code!', { theme: 'dark' });
                            setLoading(false);
                            setVerificationCode('');
                            verificationError = true
                            return
                        }
                        if (credentialErr.code === "auth/account-exists-with-different-credential") {
                            credentialErrorCode = true;
                        }
                    }
                } else {
                    toast.warn('The verification code is not correct!', { theme: 'dark' });
                    verificationError = true;
                    setLoading(false);
                    return
                }
            } catch (error) {
                setLoading(false)
                // Please keep this console log for debugging purposes
                // TODO: Inegrate sentry
                console.log('Error. Something went wrong', error)
            } finally {
                if (credentialErrorCode) {
                    toast.warn('This phone number was already associated with another account! Please try to use another phone number.', { theme: 'dark' })
                    setLoading(false);
                    setIsVerifying(false);
                    LocalStorage.clear();
                    auth.signOut();
                    setAllStepsDone(false)
                } else {
                    if (!verificationError) {
                        setLoading(false)
                        setIsVerifying(false);
                        LocalStorage.clear();
                        auth.signOut();
                        setAllStepsDone(true)
                        //Redirect to portal page.
                        const url = window.location.search
                        let params = new URLSearchParams(url);
                        const gotolocation = params.getAll('gotolocation')[0];
                        // Check if we have gotolocation url param. If not, display the default 'You are all set!' message.
                        if (gotolocation) {
                            window.location.replace(gotolocation);
                        }
                    }
                }
            }
        } else {
            toast.warn('Please type the verification code received on your phone!', { theme: 'dark' })
        }
    };

    return (
        <>
            {allStepsDone ? <>
                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4'>
                        <h3>
                            You are all set!
                        </h3>
                        <p>You will be redirected to the login page of the portal.</p>
                    </div>
                    <div className='col-4'></div>
                </div>
            </> :
                <>
                    {isVerifying ?
                        (<>
                            <div className='row'>
                                <div className='col-4'></div>
                                <div className='col-4'>
                                    <p>Type the received verification code</p>
                                    <TextField sx={
                                        textFieldSx
                                    }
                                        onChange={(event) => {
                                            setVerificationCode(event.target.value);
                                        }}
                                        disabled={loading}
                                        autoFocus
                                        type="text"
                                        id="verify-code"
                                        label=""
                                        value={verificationCode}
                                        variant="outlined"
                                        placeholder='Type the received verification code...' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'></div>
                                <div className='col-4'>
                                    <div>
                                        <div className='portal-custom-btn' onClick={handleVerifyCode} style={loading ? { pointerEvents: 'none' } : {}}>
                                            <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'VERIFY CODE'}</span>
                                            {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='Verify Code' /></span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        ) :
                        (
                            <>
                                <div className='row'>
                                    <div className='col-12 text-align-center'>
                                        <h3>
                                            Reset your password
                                        </h3>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <p>Type your password:</p>
                                        <TextField sx={
                                            textFieldSx
                                        }
                                            onChange={(event) => {
                                                setPassword(event.target.value)
                                            }}
                                            disabled={loading}
                                            type="password"
                                            id="password"
                                            label=""
                                            value={password}
                                            variant="outlined"
                                            placeholder='Type your password...' />
                                        {password && <PasswordStrengthBar password={password} onChangeScore={(value) => {
                                            // available values: 0 is too short; 1 is weak; 2 is okay; 3 is good; 4 is strong
                                            if (value === 3 || value === 4) {
                                                setPasswordIsStrong(true)
                                            } else {
                                                setPasswordIsStrong(false)
                                            }
                                        }} />}
                                    </div>
                                    <div className='col-4'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <p>Confirm your password:</p>
                                        <TextField sx={
                                            textFieldSx
                                        }
                                            onChange={(event) => {
                                                setConfirmPassword(event.target.value)
                                            }}
                                            disabled={loading}
                                            type="password"
                                            id="confirm-password"
                                            label=""
                                            value={confirmPassword}
                                            variant="outlined"
                                            placeholder='Confirm your password...' />
                                    </div>
                                    <div className='col-4'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <p>Type your phone number:</p>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            disabled={loading}
                                            placeholder="Type your phone number..."
                                            value={phone}
                                            defaultCountry={'US'}
                                            onChange={(value) => {
                                                setPhone(value)
                                            }} />
                                    </div>
                                    <div className='col-4'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                        <div>
                                            <div className='portal-custom-btn' onClick={resetPassword} style={loading ? { pointerEvents: 'none' } : {}}>
                                                <span className='portal-custom-btn-text'>{loading ? <CircularProgress color="inherit" sx={circularProgress} /> : 'SAVE'}</span>
                                                {!loading && <span className='portal-custom-btn-icon'><img src={AddPlus} alt='add user' /></span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </>}
            <div className='row' id="recaptcha-container"></div>
        </>
    );
}
