import React from 'react';
import { YourTeam } from './partials/YourTeam'
import { ActiveUsers } from './partials/ActiveUsers'
export const ManageUsers = ({ seats, portal, slug, callback }) => {
    let statusCollection = seats.seatDetails.filter((v, i, a) => a.findIndex(v2 => (v2.status === v.status)) === i).map(value => {
        if (value.status === 'ACTIVATING') {
            return 'INVITED'
        } else if (value.status === 'DEACTIVATING') {
            return 'ACTIVATED'
        } else {
            return value.status
        }
    });
    return (
        <div className='row'>
            <div className='col-4'>
                <YourTeam seats={seats} portal={portal} slug={slug} callback={callback} />
            </div>
            <div className='col-7'>
                <ActiveUsers seats={seats} portal={portal} slug={slug} statusCollection={statusCollection} />
            </div>
        </div>
    )
}
