const extractParams = (urlString, params) => {
    const url = new URLSearchParams(urlString);

    const paramsData = {};
    const paramsMissing = [];
    params.forEach(param => {
      const value = url.get(param);
      if (value) {
        paramsData[param] = value;
      } else {
        paramsMissing.push(param);
      }
    });

    return paramsData;
}

const extractPathName = (urlString) => {
    const url = new URL(urlString);
    return url.pathname.replace('/', '');
}

exports.extractParams = extractParams;
exports.extractPathName = extractPathName;
